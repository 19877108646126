import { Button, Grid, PasswordController } from "@pushpress/shared-components";
import { Control, useController } from "react-hook-form";
import { PasswordCheckForm } from "./SignInForm.utils";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { useFocusInput } from "../../../hooks/useFocusInput";

type PasswordCheckProps = {
  control: Control<PasswordCheckForm>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  goToAlternativeLogin: () => void;
  loading?: boolean;
};

function PasswordCheck({
  control,
  handleSubmit,
  goToAlternativeLogin,
  loading,
}: PasswordCheckProps) {
  const { t } = useTranslation("signin");
  const firstLoad = useRef(true);
  const inputRef = useFocusInput();
  const password = useController({
    control,
    name: "password",
  });

  useEffect(() => {
    if (firstLoad.current) {
      password.field.onChange("");
      firstLoad.current = false;
    }
  }, [password.field]);

  return (
    <form onSubmit={handleSubmit} id="sign-in-password-check-step">
      <PasswordController
        label={t("form.fields.password")}
        control={control}
        name="password"
        required
        size="large"
        ref={inputRef}
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            text={t("form.dontKnowThePassword")}
            onClick={goToAlternativeLogin}
            buttonType="text"
            size="large"
            type="button"
          />
        </Grid>
        <Grid item>
          <Button
            text={t("form.login")}
            type="submit"
            size="large"
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default PasswordCheck;
