import { z } from "zod";
import { isCypress, REFRESH_TOKEN, USER_TOKEN } from "../api.utils";
import { setCookie } from ".";

const cookieDomain = isCypress
  ? "localhost"
  : process.env.NEXT_PUBLIC_PUSHPRESS_COOKIE_WEBSITE;

export const accessTokenSchema = z.object({
  clientUuid: z.string(),
  originClientUuid: z.string().nullish(),
  role: z.string(),
});

/**
 * Set the access token and refresh token cookies
 * for now refresh token is optional but in the near future will always be set with an access token
 *
 */
export const setTokenCookies = (
  accessToken: string,
  refreshToken?: string | null,
) => {
  if (refreshToken) {
    setCookie(REFRESH_TOKEN, refreshToken, {
      domain: cookieDomain,
      sameSite: "Lax",
    });
  }
  setCookie(USER_TOKEN, accessToken, {
    domain: cookieDomain,
    sameSite: "Lax",
  });
};
