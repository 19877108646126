import { Button, Icons, MenuButton } from "@pushpress/shared-components";
import { useTranslation } from "react-i18next";

type EmailLogoutButtonProps = {
  email?: string;
  goToAccountCheckStep: VoidFunction;
  isAuthenticated: boolean;
};

function EmailLogoutButton({
  email,
  isAuthenticated,
  goToAccountCheckStep,
}: EmailLogoutButtonProps) {
  const { t } = useTranslation("signin");

  if (!isAuthenticated) {
    return (
      <Button
        className="mt-1"
        text={email}
        size="medium"
        buttonType="off"
        fullWidth
        onClick={goToAccountCheckStep}
        icon={Icons.User}
        iconLocation="left"
      />
    );
  }

  return (
    <MenuButton
      className="mt-1"
      align="center"
      anchorMargin={10}
      text={email}
      size="medium"
      buttonType="off"
      color="wolf-800"
      icon={Icons.User}
      iconLocation="left"
      menuItems={[
        {
          icon: Icons.Logout,
          label: t("logout"),
          onClick: () => (window.location.href = "/login"),
        },
      ]}
    />
  );
}

export default EmailLogoutButton;
