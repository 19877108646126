import { Button, EmailController, Grid } from "@pushpress/shared-components";
import { Control } from "react-hook-form";
import { AccountCheckForm } from "./SignInForm.utils";
import { useTranslation } from "react-i18next";
import { useFocusInput } from "../../../hooks/useFocusInput";

type AccountCheckProps = {
  control: Control<AccountCheckForm>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
};

function AccountCheck({ control, handleSubmit, loading }: AccountCheckProps) {
  const { t } = useTranslation("signin");
  const inputRef = useFocusInput();

  return (
    <form onSubmit={handleSubmit} id="sign-in-account-check-step">
      <EmailController
        label={t("form.fields.email")}
        control={control}
        name="email"
        required
        size="large"
        ref={inputRef}
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            text={t("form.createAccount")}
            buttonType="text"
            size="large"
            href="https://www.pushpress.com/signup"
          />
        </Grid>
        <Grid item>
          <Button
            text={t("form.next")}
            type="submit"
            size="large"
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default AccountCheck;
