import { Fragment } from "react";
import { Divider, Grid, Text } from "@pushpress/shared-components";
import { AuthenticatedUserFragment } from "./SignIn-generated.hooks";
import PushPressLogo from "../../../../public/images/pp-logo-icon.svg";
import styles from "./SignInForm.module.scss";
import { shouldShowRole } from "./SignInForm.utils";

type SelectAccountProps = {
  profiles: AuthenticatedUserFragment["user"]["profiles"]["data"];
  loginToControlPanel: (clientUuid: string, subdomain: string) => void;
  goToRestrictModal: (companyName: string) => void;
  goToMemberAppQRCodePage: (subdomain: string) => void;
};

function SelectAccount({
  profiles,
  loginToControlPanel,
  goToRestrictModal,
  goToMemberAppQRCodePage,
}: SelectAccountProps) {
  return (
    <Grid container>
      {profiles.map((profile, key) => {
        return (
          <Fragment key={key}>
            <Divider />
            <button
              className={styles.selectProfileButton}
              onClick={() => {
                // If profile has control panel access
                // Finish the login
                if (profile.hasControlPanelAccess) {
                  loginToControlPanel(
                    profile.client.uuid,
                    profile.client.subdomain,
                  );
                  return;
                }

                // If is staff but didn't have control panel access
                // Go to restrict modal
                if (profile.isStaff) {
                  goToRestrictModal(profile.client.company);
                  return;
                }

                // If is not staff, go to member app QR code page
                goToMemberAppQRCodePage(profile.client.subdomain);
                return;
              }}
            >
              <span className={styles.clientLogoAndName}>
                <img
                  src={profile.client.logoUrl ?? PushPressLogo.src}
                  alt={`${profile.client.company} logo`}
                  height={24}
                  width={24}
                  className="mr-2"
                  style={{
                    objectFit: "contain",
                  }}
                />
                <Text
                  component="span"
                  variant="heading-5"
                  whiteSpace="nowrap"
                  className={styles.companyName}
                >
                  {profile.client.company}
                </Text>
              </span>
              {!!profile.membershipRole &&
                !!shouldShowRole(profile.membershipRole) && (
                  <span className={styles.status}>
                    <Text component="span" variant="body-sm" color="wolf-700">
                      {profile.membershipRole}
                    </Text>
                  </span>
                )}
            </button>
          </Fragment>
        );
      })}
      <Divider />
    </Grid>
  );
}

export default SelectAccount;
