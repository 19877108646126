import React from "react";
import Head from "next/head";
import SignInForm from "../../screens/signin/SignInForm";
import { GetServerSideProps } from "next";
import {
  getAppProps,
  getSubdomain,
  isNonBrandedSubdomain,
} from "../../utils/server";
import { AppPageProps } from "../_app.page";
import { useTranslation } from "react-i18next";
import * as jsonwebtoken from "jsonwebtoken";
import { accessTokenSchema } from "../../utils/authUtils";
import { USER_TOKEN } from "../../api.utils";

type LoginPageProps = AppPageProps & {
  client?: Client;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  // subdomain
  const subdomain = getSubdomain(context.req);

  if (isNonBrandedSubdomain(subdomain)) {
    return {
      props: {},
    };
  }

  const appProps = await getAppProps(context);

  // handle redirects
  if ("redirect" in appProps) {
    return appProps;
  }

  // return if there is an error
  if ("error" in appProps) {
    return {
      props: appProps,
    };
  }

  const accessToken = context.req.cookies[USER_TOKEN];

  if (accessToken) {
    const decodedToken = jsonwebtoken.decode(accessToken);
    const brandedClientUuid = appProps.client.uuid;
    const result = accessTokenSchema.safeParse(decodedToken);

    if (result.success) {
      const { clientUuid, originClientUuid, role } = result.data;

      if (
        clientUuid === brandedClientUuid ||
        (originClientUuid === brandedClientUuid && role === "superuser")
      ) {
        const protocol =
          process.env.NEXT_PUBLIC_ENV === "local" ? "http" : "https";
        return {
          redirect: {
            destination: `${protocol}://${subdomain}.${process.env.NEXT_PUBLIC_API_V1_BASE_DOMAIN}/dashboard/implicit_grant`,
            permanent: false,
          },
        };
      }
    }
  }

  const favicon = appProps.client.logoUrl || null;

  const pageProps: LoginPageProps = {
    ...appProps,
    title: "",
    favicon,
    sessionId: "login",
    disableStripe: true,
  };

  return {
    props: pageProps,
  };
};

function Login(props: LoginPageProps) {
  const { t } = useTranslation("signin");

  return (
    <>
      <Head>
        <title>
          {`${props.client?.company ?? "PushPress"} | ${t("login")}`}
        </title>
      </Head>
      <SignInForm client={props.client} />
    </>
  );
}

export default Login;
