import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AccountCheckForm,
  PasswordCheckForm,
  STEP,
  firstStep,
  steps,
} from "./SignInForm.utils";
import AuthenticationLayout from "../../../components/AuthenticationLayout";
import { motion } from "framer-motion";
import StepFlow from "../../../components/StepFlow";
import IndefiniteLoadingBar from "../../../components/InfiniteLoadingBar";
import {
  AuthenticatedUserFragment,
  useAuthenticateUserForSignInMutation,
  useAuthenticateUserWithCodeForSignInMutation,
  useGenerateCodeLoginForSignInMutation,
  useGetUserProfileStatusForSignInLazyQuery,
  useLoginToControlPanelWithCodeMutation,
  useUpdatePasswordMutation,
} from "./SignIn-generated.hooks";
import AccountCheck from "./AccountCheck";
import PasswordCheck from "./PasswordCheck";
import SelectAccount from "./SelectAccount";
import EmailLogoutButton from "./components/EmailLogoutButton";
import { toast } from "react-toastify";
import { extractError } from "../../../utils";
import { defaultToastOptions } from "../../../utils/toastUtils";
import { isCypress } from "../../../api.utils";
import { Grid, Loader, useToggle } from "@pushpress/shared-components";
import dynamic from "next/dynamic";
import { setTokenCookies } from "../../../utils/authUtils";
import AlternativeLogin from "./../../../components/AlternativeLogin";
import OTPCodeCheck, {
  OTPCodeCheckForm,
} from "./../../../components/OTPCodeCheck";
import OTPResetPassword, {
  OTPResetPasswordForm,
} from "./../../../components/OTPResetPassword";
import SuccessUpdatingPassword from "../../../components/OTPResetPassword/SuccessUpdatingPassword";
import { useAnalytics } from "../../../components/AnalyticsProvider";

const RestrictedAccessModal = dynamic(
  () => import("./components/RestrictedAccessModal"),
  {
    ssr: false,
  },
);

type SignInFormProps = {
  client?: Client;
};

const DEFAULT_SIGNIN_CONTENT_HEIGHT = 122;

function SignInForm({ client }: SignInFormProps) {
  const analytics = useAnalytics();
  const {
    company = "PushPress",
    logoUrl,
    uuid: clientUuid,
    subdomain,
  } = client ?? {};
  const router = useRouter();
  const { step = firstStep } = router.query as {
    step: STEP;
  };
  const { t } = useTranslation("signin");
  const [authenticatedUser, setAuthenticatedUser] =
    useState<AuthenticatedUserFragment>();
  const [getUserStatus, { loading: loadingCheckingAccount }] =
    useGetUserProfileStatusForSignInLazyQuery({
      fetchPolicy: "no-cache",
      onError(err) {
        toast.error(extractError(err), defaultToastOptions);
      },
    });
  const [generateCode] = useGenerateCodeLoginForSignInMutation({
    onError(err) {
      toast.error(extractError(err), defaultToastOptions);
    },
  });
  const [newPassword, setNewPassword] = useState<string | undefined>();
  const [restrictedCompanyName, setRestrictedCompanyName] = useState<
    string | undefined
  >();
  const [restrictedModal, restrictedModalAction] = useToggle();
  const [redirectLoading, redirectLoadingActions] = useToggle();
  const [
    loginToControlPanelWithCode,
    { loading: loadingLoginIntoControlPanel },
  ] = useLoginToControlPanelWithCodeMutation({
    onError() {
      // The code used to perform the login is always generated and stored by us,
      // so it will only fail if the service fails,
      // or if the user take more than the code ttl (5 minutes) to finish the login
      toast.error(t("form.errors.authSessionExpired"), defaultToastOptions);
      window.location.href = "/login";
    },
  });
  const [authenticateUserMutation, { loading: loadingCheckingPassword }] =
    useAuthenticateUserForSignInMutation();
  const [
    authenticateUserWithCode,
    { loading: loadingAuthenticateUserWithCode },
  ] = useAuthenticateUserWithCodeForSignInMutation();
  const [accountCheckFormValues, setAccountCheckFormValues] =
    useState<AccountCheckForm>();
  const {
    control: otpCodeCheckForm,
    handleSubmit: handleSubmitOTPCodeCheckForm,
    setError: setOTPCodeCheckError,
  } = useForm<OTPCodeCheckForm>();
  const [updatePasswordMutation, { loading: loadingUpdatingPassword }] =
    useUpdatePasswordMutation();
  const {
    control: resetPasswordForm,
    handleSubmit: handleResetPasswordForm,
    setError: setResetPasswordError,
  } = useForm<OTPResetPasswordForm>({
    mode: "onBlur",
  });
  const {
    control: accountCheckForm,
    handleSubmit: handleSubmitAccountCheckForm,
    setError: setAccountCheckError,
  } = useForm<AccountCheckForm>();
  const {
    control: passwordCheckForm,
    handleSubmit: handleSubmitPasswordCheckForm,
    setError: setPasswordCheckError,
  } = useForm<PasswordCheckForm>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const analyticsProperties = useMemo(() => {
    return {
      clientUuid,
      company,
      subdomain,
    };
  }, [clientUuid, company, subdomain]);

  const trackAnalyticsEvent = useCallback(
    (event: string) => {
      analytics.trackEvent(`login.${event}`, analyticsProperties, true);
    },
    [analytics, analyticsProperties],
  );

  const goToStep = useCallback(
    (stepName: STEP) => {
      trackAnalyticsEvent(`${stepName}.started`);
      router.push(`/login?step=${stepName}`, {
        query: {
          step: stepName,
        },
      });
    },
    [router, trackAnalyticsEvent],
  );

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      trackAnalyticsEvent("started");
      goToStep("accountCheck");
    }
  }, [isFirstLoad, goToStep, trackAnalyticsEvent]);

  const goToMemberQRCodePage = useCallback(
    (clientSubdomain: string, authenticateUserName?: string) => {
      const name = authenticateUserName ?? authenticatedUser?.user.firstName;

      const host = window.location.host;
      const pageSubdomain = host.split(".")[0];

      const memberAppUrl = `${window.location.protocol}//${host.replace(
        pageSubdomain,
        clientSubdomain,
      )}/landing/member-app-qrcode`;
      trackAnalyticsEvent("redirectTo.memberQrCodePage");
      router.push(
        {
          pathname: memberAppUrl,
          query: {
            name,
          },
        },
        memberAppUrl,
      );
    },
    [router, authenticatedUser, trackAnalyticsEvent],
  );

  const loginToControlPanel = useCallback(
    async (clientUuid: string, clientSubdomain: string, code: string) => {
      if (!accountCheckFormValues?.email) return;

      // Login and go to control panel
      const response = await loginToControlPanelWithCode({
        variables: {
          input: {
            clientUuid,
            email: accountCheckFormValues?.email,
            code,
          },
        },
      });
      const { accessToken, refreshToken } = response.data?.loginWithCode ?? {};

      if (accessToken) {
        redirectLoadingActions.on();

        setTokenCookies(accessToken, refreshToken);
        trackAnalyticsEvent("redirectTo.controlPanel");

        // Make the PHP login
        if (!isCypress) {
          const protocol =
            process.env.NEXT_PUBLIC_ENV === "local" ? "http" : "https";
          router.push(
            `${protocol}://${clientSubdomain}.${process.env.NEXT_PUBLIC_API_V1_BASE_DOMAIN}/dashboard/implicit_grant`,
          );
        }
      }
    },
    [
      accountCheckFormValues,
      loginToControlPanelWithCode,
      router,
      redirectLoadingActions,
      trackAnalyticsEvent,
    ],
  );

  const afterSuccessAuthentication = useCallback(
    (authenticateUser: AuthenticatedUserFragment) => {
      // If is not on a specific client domain, go select client
      if (!clientUuid || !subdomain) {
        setAuthenticatedUser(authenticateUser);
        goToStep("selectAccount");
        return;
      }

      // If trying to login into specific client, check if has control panel access
      const {
        code,
        user: { profiles },
      } = authenticateUser;

      const profile = profiles?.data.find(
        (profile) => profile.client.uuid === clientUuid,
      );

      if (!profile) {
        // If does not have profile, and did the authentication in a subdomain
        // We assume is a super user, so we just try to login into control panel
        loginToControlPanel(clientUuid, subdomain, code);
        return;
      }

      if (profile.hasControlPanelAccess && code) {
        // If yes, go to control panel
        loginToControlPanel(
          profile.client.uuid,
          profile.client.subdomain,
          code,
        );
        return;
      }

      if (profile.isStaff) {
        restrictedModalAction.on();
        setRestrictedCompanyName(profile.client.company);
      }

      // If not, go to member app QR Code page
      goToMemberQRCodePage(
        profile.client.subdomain,
        authenticateUser.user.firstName,
      );
      return;
    },
    [
      clientUuid,
      subdomain,
      goToStep,
      loginToControlPanel,
      restrictedModalAction,
      setRestrictedCompanyName,
      goToMemberQRCodePage,
    ],
  );

  const submitAccountCheckForm = useCallback(
    async (values: AccountCheckForm) => {
      const response = await getUserStatus({
        variables: {
          input: {
            clientUuid,
            email: values.email,
          },
        },
      });

      if (!response.data?.getUserProfileStatus) return;

      const { exists, hasProfileWithThisClient } =
        response.data?.getUserProfileStatus;

      // If user does not exist at all, does not allow to continue
      if (!exists) {
        setAccountCheckError("email", {
          message: t("form.errors.accountNotFound"),
        });
        trackAnalyticsEvent("account.notFound");
        return;
      }
      trackAnalyticsEvent("account.found");

      // If is inside a subdomain (clientUuid is not empty), check if it has a profile with this client
      if (clientUuid && !hasProfileWithThisClient) {
        // If not, display an error message
        setAccountCheckError("email", {
          message: t("form.errors.profileNotFoundWithClient"),
        });
        trackAnalyticsEvent("profile.notFound");
        return;
      } else if (clientUuid && hasProfileWithThisClient) {
        trackAnalyticsEvent("profile.found");
      }

      // If is not on a specific subdomain, or it has a profile with the subdomain, go validate the password
      setAccountCheckFormValues(values);
      goToStep("passwordCheck");
    },
    [
      t,
      goToStep,
      getUserStatus,
      setAccountCheckError,
      clientUuid,
      trackAnalyticsEvent,
    ],
  );

  const submitPasswordCheckForm = useCallback(
    async (values: PasswordCheckForm) => {
      if (!accountCheckFormValues?.email) return;

      // Authenticate the user with password
      const { data } = await authenticateUserMutation({
        variables: {
          authenticateUserInput: {
            email: accountCheckFormValues?.email,
            password: values.password,
          },
          profilesFilter: {
            clientUuid,
          },
          profilesPagination: {
            limit: 20,
            page: 1,
            offset: undefined,
            sortBy: {
              field: "userType",
              type: "ASC",
            },
          },
        },
      });

      if (!data) return;

      const responseType = data.authenticateUser.__typename;

      if (responseType === "AuthUserFailed") {
        setPasswordCheckError("password", {
          message: t("form.errors.wrongPassword"),
        });
        trackAnalyticsEvent("password.wrong");
        return;
      }

      if (responseType === "AuthUserSuccess") {
        trackAnalyticsEvent("password.success");
        afterSuccessAuthentication(data.authenticateUser);
        return;
      }
    },
    [
      t,
      afterSuccessAuthentication,
      authenticateUserMutation,
      setPasswordCheckError,
      trackAnalyticsEvent,
      accountCheckFormValues?.email,
      clientUuid,
    ],
  );

  const submitOtpCodeCheckForm = useCallback(
    async (values: OTPCodeCheckForm) => {
      if (!accountCheckFormValues?.email) return;

      const { data } = await authenticateUserWithCode({
        variables: {
          authenticateUserWithCodeInput: {
            email: accountCheckFormValues?.email,
            code: values.code,
          },
          profilesFilter: {
            clientUuid,
          },
          profilesPagination: {
            limit: 20,
            page: 1,
            offset: undefined,
            sortBy: {
              field: "userType",
              type: "ASC",
            },
          },
        },
      });

      if (!data?.authenticateUserWithCode) return;

      const { __typename: responseType } = data.authenticateUserWithCode;

      if (responseType === "AuthUserFailed") {
        setOTPCodeCheckError("code", {
          message: t("form.errors.invalidCode"),
        });
        trackAnalyticsEvent("code.fail");
        return;
      }

      if (responseType === "AuthUserSuccess") {
        trackAnalyticsEvent("code.success");
        afterSuccessAuthentication(data.authenticateUserWithCode);
        return;
      }
    },
    [
      t,
      authenticateUserWithCode,
      setOTPCodeCheckError,
      accountCheckFormValues?.email,
      afterSuccessAuthentication,
      clientUuid,
      trackAnalyticsEvent,
    ],
  );

  const submitResetPasswordForm = useCallback(
    async (values: OTPResetPasswordForm) => {
      if (!accountCheckFormValues?.email) return;

      const updatePasswordResponse = await updatePasswordMutation({
        variables: {
          input: {
            email: accountCheckFormValues.email,
            code: values.code,
            newPassword: values.newPassword,
            clientUuid: undefined,
            currentPassword: undefined,
          },
        },
        context: {
          headers: {
            Authorization: undefined,
          },
        },
      });

      const success = updatePasswordResponse.data?.updatePassword;

      if (!success) {
        setResetPasswordError("code", {
          message: t("form.errors.invalidCode"),
        });
        trackAnalyticsEvent("resetPasswordCode.fail");
        return;
      }

      trackAnalyticsEvent("resetPasswordCode.success");
      goToStep("successUpdatingPassword");
      setNewPassword(values.newPassword);
    },
    [
      accountCheckFormValues?.email,
      updatePasswordMutation,
      setResetPasswordError,
      goToStep,
      t,
      trackAnalyticsEvent,
    ],
  );

  const stepText = useMemo<{
    title: ReactNode;
    description?: ReactNode;
  }>(() => {
    const emailDescription = (
      <EmailLogoutButton
        goToAccountCheckStep={() => goToStep("accountCheck")}
        isAuthenticated={!!authenticatedUser}
        email={accountCheckFormValues?.email}
      />
    );

    switch (step) {
      case "accountCheck":
        return {
          title: t("accountCheck.title"),
          description: t("accountCheck.description", { company }),
        };
      case "passwordCheck":
        return {
          title: t("passwordCheck.title"),
          description: emailDescription,
        };
      case "alternativeLogin":
        return {
          title: t("alternativeLogin.title"),
          description: emailDescription,
        };
      case "selectAccount":
        return {
          title: t("selectAccount.title"),
          description: emailDescription,
        };
      case "OTPCodeCheck":
        return {
          title: t("OTPCodeCheck.title"),
          description: emailDescription,
        };
      case "resetPassword":
        return {
          title: t("resetPassword.title"),
          description: emailDescription,
        };
      case "successUpdatingPassword":
        return {
          title: t("successUpdatingPassword.title"),
          description: t("successUpdatingPassword.description"),
        };
      default:
        return {
          title: "",
          description: "",
        };
    }
  }, [
    step,
    t,
    accountCheckFormValues?.email,
    company,
    authenticatedUser,
    goToStep,
  ]);

  const showInfiniteLoadingBar =
    loadingCheckingAccount ||
    loadingCheckingPassword ||
    loadingLoginIntoControlPanel ||
    loadingAuthenticateUserWithCode ||
    loadingUpdatingPassword ||
    redirectLoading;

  return (
    <AuthenticationLayout
      title={stepText.title}
      description={stepText.description}
      logoUrl={logoUrl}
    >
      <RestrictedAccessModal
        companyName={restrictedCompanyName}
        visible={restrictedModal}
        onConfirm={() => restrictedModalAction.off()}
      />
      {showInfiniteLoadingBar && <IndefiniteLoadingBar />}
      {redirectLoading || loadingLoginIntoControlPanel ? (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
          marginY={6}
        >
          <Loader />
        </Grid>
      ) : (
        <StepFlow
          step={steps[step]}
          initialContainerHeight={DEFAULT_SIGNIN_CONTENT_HEIGHT}
        >
          <motion.div key={steps["accountCheck"]}>
            <AccountCheck
              control={accountCheckForm}
              handleSubmit={handleSubmitAccountCheckForm(
                submitAccountCheckForm,
              )}
              loading={loadingCheckingAccount}
            />
          </motion.div>
          <motion.div key={steps["passwordCheck"]}>
            <PasswordCheck
              control={passwordCheckForm}
              handleSubmit={handleSubmitPasswordCheckForm(
                submitPasswordCheckForm,
              )}
              loading={loadingCheckingPassword || loadingLoginIntoControlPanel}
              goToAlternativeLogin={() => goToStep("alternativeLogin")}
            />
          </motion.div>
          <motion.div key={steps["alternativeLogin"]}>
            <AlternativeLogin
              goToOTPLogin={() => {
                if (!accountCheckFormValues?.email) return;

                generateCode({
                  variables: {
                    input: {
                      clientUuid: undefined, // clientUuid is deprecated
                      email: accountCheckFormValues?.email,
                    },
                  },
                });
                goToStep("OTPCodeCheck");
              }}
              goToResetPassword={() => {
                if (!accountCheckFormValues?.email) return;

                generateCode({
                  variables: {
                    input: {
                      clientUuid: undefined, // clientUuid is deprecated
                      email: accountCheckFormValues?.email,
                    },
                  },
                });

                goToStep("resetPassword");
              }}
            />
          </motion.div>
          <motion.div key={steps["OTPCodeCheck"]}>
            <OTPCodeCheck
              email={accountCheckFormValues?.email}
              control={otpCodeCheckForm}
              handleSubmit={handleSubmitOTPCodeCheckForm(
                submitOtpCodeCheckForm,
              )}
              loading={
                loadingAuthenticateUserWithCode || loadingLoginIntoControlPanel
              }
            />
          </motion.div>
          <motion.div key={steps["resetPassword"]}>
            <OTPResetPassword
              email={accountCheckFormValues?.email}
              control={resetPasswordForm}
              loading={loadingUpdatingPassword}
              handleSubmit={handleResetPasswordForm(submitResetPasswordForm)}
            />
          </motion.div>
          <motion.div key={steps["successUpdatingPassword"]}>
            <SuccessUpdatingPassword
              goToAccount={() => {
                if (!newPassword) return;

                submitPasswordCheckForm({
                  password: newPassword,
                });
              }}
            />
          </motion.div>
          <motion.div key={steps["selectAccount"]}>
            <SelectAccount
              profiles={authenticatedUser?.user?.profiles?.data ?? []}
              loginToControlPanel={(
                clientUuid: string,
                clientSubdomain: string,
              ) => {
                if (!authenticatedUser?.code) return;
                loginToControlPanel(
                  clientUuid,
                  clientSubdomain,
                  authenticatedUser?.code,
                );
              }}
              goToMemberAppQRCodePage={(subdomain: string) =>
                goToMemberQRCodePage(subdomain)
              }
              goToRestrictModal={(companyName: string) => {
                restrictedModalAction.on();
                setRestrictedCompanyName(companyName);
              }}
            />
          </motion.div>
        </StepFlow>
      )}
    </AuthenticationLayout>
  );
}

export default SignInForm;
