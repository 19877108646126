import { GrowthBook } from "@growthbook/growthbook";
import Cookies, { CookieAttributes } from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { isHttps } from ".";
import { getRootDomain } from "./browserUtils";

export const generateGrowthBook = () => {
  return new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NEXT_PUBLIC_ENV !== "production",
  });
};

export const GB_UUID_COOKIE = "gb_anonymous_id";

export const getGrowthBookCookie = () => {
  let uuid = Cookies.get(GB_UUID_COOKIE);
  const host = window.location.host;
  const domain = getRootDomain(host);

  const cookieAttributes: CookieAttributes = isHttps()
    ? {
        domain: domain,
        secure: true,
        sameSite: "None",
      }
    : {
        domain: domain,
      };

  if (uuid) {
    // To ensure that existing cookies have the correct domain, we remove the
    // cookie and set it again. There is a chance that the cookie was set without
    // a domain, so we attempt to remove it with and without a domain.
    Cookies.remove(GB_UUID_COOKIE, { domain });
    Cookies.remove(GB_UUID_COOKIE);
    Cookies.set(GB_UUID_COOKIE, uuid, cookieAttributes);
  } else {
    uuid = uuidv4();
    Cookies.set(GB_UUID_COOKIE, uuid, cookieAttributes);
  }

  return Cookies.get(GB_UUID_COOKIE);
};
