export type STEP =
  | "accountCheck"
  | "passwordCheck"
  | "alternativeLogin"
  | "OTPCodeCheck"
  | "resetPassword"
  | "successUpdatingPassword"
  | "selectAccount";

export const firstStep: STEP = "accountCheck";

export const steps: Record<STEP, number> = {
  accountCheck: 1,
  passwordCheck: 2,
  alternativeLogin: 3,
  OTPCodeCheck: 4,
  resetPassword: 5,
  successUpdatingPassword: 6,
  selectAccount: 7,
};

export interface AccountCheckForm {
  email: string;
}

export interface PasswordCheckForm {
  password: string;
}

const hiddenRoles = ["lead", "non-member"];
export const shouldShowRole = (membershipRole: string) => {
  return !hiddenRoles.includes(membershipRole);
};
