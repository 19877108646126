import { ReactNode } from "react";
import PushPressLogo from "../../assets/pp-logo-primary.svg";
import styles from "./AuthenticationLayout.module.scss";
import { Grid, Text } from "@pushpress/shared-components";

type AuthenticationLayoutProps = {
  logoUrl?: string;
  children: ReactNode;
  title: ReactNode;
  description?: ReactNode;
};

function AuthenticationLayout({
  logoUrl,
  title,
  description,
  children,
}: AuthenticationLayoutProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img
              src={logoUrl ? logoUrl : PushPressLogo.src}
              alt="PushPress logo"
              className={styles.logo}
            />
          </Grid>
          <Grid item>
            <Text variant="heading-4">{title}</Text>
          </Grid>
          {description && (
            <Grid item>
              <Text variant="body-lg" className="mb-4" align="center">
                {description}
              </Text>
            </Grid>
          )}
        </Grid>
        {children}
      </div>
    </div>
  );
}

export default AuthenticationLayout;
