import React from "react";
import { Transition, motion } from "framer-motion";
import styles from "./InfiniteLoadingBar.module.scss";

const IndefiniteLoadingBar = () => {
  const loadingBarVariants = {
    start: { scaleX: 0, x: 0 },
    end: { scaleX: 1, x: "100%" },
  };

  const transition: Transition = {
    repeat: Infinity,
    repeatType: "loop",
    duration: 1,
    ease: "linear",
  };

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.bar}
        variants={loadingBarVariants}
        initial="start"
        animate="end"
        transition={transition}
      />
    </div>
  );
};

export default IndefiniteLoadingBar;
